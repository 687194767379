body {
  background-color: #0b131b;
  color: #1eeb92;

  font-family: Arial, Helvetica, sans-serif;

  font-size: 15px;
  font-weight: normal;

  line-height: 1em;
  margin: 0;
}

nav{
  background-color: #0b131b;
  height: 200px;
  margin:0px auto !important;
}

.navbar-logo-wrapper{
  width:450px;
  margin: 0px auto;
}

.navbar-logo{
  background:url('images/dummy-logo.png') center center no-repeat !important;
  margin:0px auto !important;
  height: 200px;
  width: 100%;
}

.outer {
  padding: 10px;
}

.Title{
  margin-left: 0px !important
}

.container {
  width: 80%;
  margin: auto;
  border: 5px #333333 solid;
  padding: 10px;
  border-radius: 25px;
}

/* swap container */
.makeStyles-paperContainer-1 {
  padding: 8px;
  border-radius: 16px;
  padding-bottom: 24px;
  color: #2c2c2c !important;
}
[type=submit]:not(:disabled){
  background-color: #0b131b ;
  color: #fff !important;
}

/* swap button */
.MuiButton-containedPrimary {
  color: #ffffff !important;
  background-color: #0b131b ;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiPaper-root {
  color: rgba(0, 0, 0, 0.87) !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff !important;
}

/* footer */
.makeStyles-footer {
  margin-top: 100px !important;
}

/* swap pop up */

.MuiDialog-scrollPaper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
}

.MuiDialog-paperFullWidth {
  width: calc(100% - 100px) !important;
}

.MuiDialog-paperWidthSm {
  max-width: 500px !important;
}

.MuiButton-textPrimary {
  color: #0b131b !important;
  width: 100% !important;
}

.MuiDialogActions-root {
  flex: 0 0 auto;
  display: flex;
  padding: 8px;
  align-items: center !important;
  justify-content: center !important;
}

.MuiIconButton-root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 10px;
  width: 50px;
  height: 50px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 20px;
  margin: 0;
  display: block;
  padding: 5px 10px 10px !important;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.makeStyles-coinList-13 {
  height: 300px;
  overflow-y: scroll;
  background: beige;
}

/* swap input bk */
.makeStyles-container-16 {
  padding: 0px 20px !important;
  min-height: 50px !important;
  border-color: #e0e0e0;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  background-color: #fafafa;
}

/* swap button */
.MuiButton-containedPrimary {
  color: #ffffff;
  background-color: #0b131b;
}

/* token button */
.MuiFab-extended.MuiFab-sizeSmall {
  width: auto;
  height: 34px;
  padding: 0px 5px 0px 15px !important;
  min-width: 34px;
  border-radius: 17px;
  background-color: #0b131b !important;
  color: #ffffff;
}

/* footer */

.makeStyles-footer-7 {
  margin-top: 50px !important;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 2.em !important;
  height: 1.5em !important;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.input {
  width: 1;
  color: #777;
  padding: 8;
  margin: 10;
  width: 200;
  color:#ffffff !important
}

.myform {
  padding: 20px;
}

.myform input[type="text"],
.myform textarea {
  padding: 8px;
  width: 100%;
}

.column {
  padding: 20px;
  float: left;
  width: 50%;
}

.column_aut {
  padding: 20px;
  float: left;
  width: 33%;
}

.Token_message {
  display: inline;
}

.swap {
  padding: 10px;
}

.swap_button {
  float: right;
}

.markdown {
  width: 80%;
  margin: auto;
}

/* phone */

@media only screen and (max-width: 600px) {
  
body{
}

.navbar-logo{
}

}